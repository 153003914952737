<template>
  <b-form-group
    label="Realizado por"
    label-for="realizadoPor"
    label-class="font_size_label realizadoPor"
  >
    <validation-provider #default="{ errors }" name="Realizado por">
      <v-select
        id="realizadoPor"
        :options="options"
        v-model="selected"
        placeholder="Selecione a entidade"
        label="descricao"
        :clearable="true"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      options: [],
      selected: this.value,
    };
  },
  beforeMount() {
    this.options = [
      { descricao: "Empresa", realizado_por: "Empresa" },
      { descricao: "SESI", realizado_por: "SESI" },
    ];
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", [valor]);
    },
  },
};
</script>

