<template>
  <b-form-group
    :label="temLimiteSelecao ? `Empresa (Selecione no máx ${maxSelection})*` : 'Empresas'"
    label-for="empresa"
    label-class="font_size_label"
  >
    <validation-provider #default="{ errors }" name="Empresa" :rules="rules">
      <div class="primary-select">
        <v-select
          id="empresa"
          :options="options"
          v-model="selected"
          :placeholder="placeholder"
          label="descricao"
          :clearable="true"
          multiselect
          multiple
          :loading="loading"
          :disabled="loading || disabled || disabledField"
          @input="checkMaxSelection"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
          <template #option="{ documento_formatado, descricao }">
            <strong>{{ descricao }}</strong><br>
            <span>{{documento_formatado}}</span>
          </template>
          <template #selected-option="{descricao}">
            <span>
              {{ descricao }}
            </span>
          </template>
        </v-select>
      </div>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import {getUserData} from "@/auth/utils";
import { TIPO_RELATORIO_ENUM } from "../config";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
    idTipoRelatorio: {
      required: true,
      type: Number
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    filters: {
      required: false,
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      options: [],
      userData: getUserData(),
      selected: this.value,
      placeholder: "Carregando...",
      loading: true,
      disabledField: false,
      parametros: {
        params: {
          paginacao: false,
          situacao_cadastro_parcial_nacional: this.idTipoRelatorio == TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID ? true : false,
          empresa_base_nacional: this.idTipoRelatorio == TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID ? true : false,
          empresa_possui_grupo: this.idTipoRelatorio == TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID ? true : false,
          colunaOrdenar: "nome_empresa",
        },
      },
      relatoriosComLimitacao: [
        TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID,
        TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID,
        TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID,
        TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID
      ],
      maxSelection: 5,
      selectedGroup: false,
      temLimiteSelecao: false,
      rules: "",
    };
  },
  computed: {
      idGrupo() {
          let idGrupo = null
          if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID && this.filters.id_grupo) {
              idGrupo = this.filters.id_grupo[0].id_grupo
          }
          return idGrupo
      }
  },
  beforeMount() {
    this.setOptions();
    this.setRules();
  },
  methods: {
    async setOptions(group = false) {
      await this.$http
        .get(this.$api.empresaRelatorioExportacao(), this.setRequestParams())
        .then(({ data }) => {
          let result = data;
          if((!this.selectedGroup && !group) || group){
          this.options = result.map((empresa) => {
            return {
              id_empresa: empresa.id_empresa,
              descricao: (this.verifyIfFildHasUfConcatenated() ? empresa.uf + ' - ' : '') + empresa.nome_empresa,
              documento: empresa.documento,
              documento_formatado: empresa.documento_formatado,
            };
          });
          }
          this.loading = false;
        });
    },
    perfilAdminDN() {
      const profiles = this.userData.perfis.map(profile => profile.id_perfil);

      return (profiles.includes(this.$perfil.ADMIN_DN));
    },

    perfilSaudeDN() {
      const profiles = this.userData.perfis.map(profile => profile.id_perfil);

      return (profiles.includes(this.$perfil.SAUDE_DN));
    },

    verifyIfFildHasUfConcatenated() {

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID) {
          return this.perfilAdminDN();
      }

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID) {
          return this.perfilSaudeDN();
      }

      return false;
    },
    checkMaxSelection() {
      if (this.temLimiteSelecao && this.selected.length > this.maxSelection) {
        this.selected.pop();
      }
    },

    setRules() {
      this.temLimiteSelecao = this.relatoriosComLimitacao.includes(this.idTipoRelatorio)
      if (this.temLimiteSelecao) {
        this.rules = "required"
      }

      if(TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID == this.idTipoRelatorio) {
        this.maxSelection = 10
      }
    },

    setRequestParams() {

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.AGENDAMENTOS_ID) {
        this.parametros.params.tem_agendamento = true;
      }

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID) {
        this.parametros.params.id_grupo = this.idGrupo;
      }

      return this.parametros;
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      const noData = this.options.length == 0
      this.disabledField = noData
      this.placeholder = noData && !this.loading
        ? "Nenhum registro encontrado"
        : "Selecione a empresa";

      this.$emit("options", valor);
    },
    idGrupo: function (val) {
      if(val) {
        this.selected = [];
      }
      this.selectedGroup = !!val;
      this.options = [];
      this.loading = true;
      this.setOptions(true);
    }
  },
};
</script>

