<template>
  <b-form-group
    label="Unidade Operacional"
    label-for="unidadeOperacional"
    label-class="font_size_label unidadeOperacional"
  >
    <validation-provider #default="{ errors }" name="Unidade Operacional">
      <v-select
        id="unidadeOperacional"
        :options="options"
        v-model="selected"
        :placeholder="placeholder"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
        :disabled="loading || disabled"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { TIPO_RELATORIO_ENUM } from "../config";


export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
    departamento: {
      required: false,
    },
    idTipoRelatorio: {
      required: false,
      type: Number
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: this.value,
      placeholder: "Carregando...",
      loading: true,
      parametros: {
        params: {
          ativo: true,
        },
      },
    };
  },
  beforeMount() {
    this.setParams();
    this.setOptions();
  },
  methods: {
    setParams() {
      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.AGENDAMENTOS_ID) {
        this.parametros.params.ativo = 'all';
      }
    },
    setOptions() {
      this.getUnidadeOperacional();
    },

    async getUnidadeOperacional() {
      await this.$http
        .get(this.$api.unidade(), this.setRequestParams())
        .then(({ data }) => {
          this.options = data.map((unidade) => {
            return {
              id_unidade: unidade.id_unidade,
              descricao: unidade.descricao_unidade,
            };
          });
        });
    },

    setRequestParams() {
      this.addOrRemoveParameter(this.parametros.params, 'id_departamento', this.departamento);

      return this.parametros;
    },

    addOrRemoveParameter(objeto, chave, valor) {
      if (valor !== undefined && valor !== null) {
        objeto[chave] = valor[chave];
      } else {
        delete objeto[chave];
      }
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.loading = this.options.length == 0;
      this.placeholder = this.loading
        ? "Nenhum registro encontrado"
        : "Selecione a unidade";
      this.$emit("options", valor);
    },

    departamento: function (valor) {
      this.setOptions();
    }
  },
};
</script>

