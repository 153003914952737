var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.temLimiteSelecao ? ("Empresa (Selecione no máx " + _vm.maxSelection + ")*") : 'Empresas',"label-for":"empresa","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"primary-select"},[_c('v-select',{attrs:{"id":"empresa","options":_vm.options,"placeholder":_vm.placeholder,"label":"descricao","clearable":true,"multiselect":"","multiple":"","loading":_vm.loading,"disabled":_vm.loading || _vm.disabled || _vm.disabledField},on:{"input":_vm.checkMaxSelection},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var documento_formatado = ref.documento_formatado;
var descricao = ref.descricao;
return [_c('strong',[_vm._v(_vm._s(descricao))]),_c('br'),_c('span',[_vm._v(_vm._s(documento_formatado))])]}},{key:"selected-option",fn:function(ref){
var descricao = ref.descricao;
return [_c('span',[_vm._v(" "+_vm._s(descricao)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])])],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }