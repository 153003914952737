<template>
  <div>
    <b-badge class="w-100 badge-light-blue">
      <span>{{ label }}</span>
      <span v-if="ehObrigatorio">*</span>
    </b-badge>
    <b-row class="pt-1">
      <b-col sm="6" md="6" lg="6">
        <validation-provider ref="inicioProvider" #default="{ errors }" name="Início" :rules="rules">
          <b-form-group
            label="Início"
            label-for="inicio-input"
            label-class="font_size_label"
          >
            <template #label>
              <span v-b-tooltip.hover.right="textTooltipDataMinima" >Início</span>
            </template>

            <b-input-group>
              <b-form-input
                class="form-control"
                id="inicio-input"
                v-model="input.inicio"
                v-mask="'##/##/####'"
                placeholder="00/00/0000"
                autocomplete="off"
                @input="setInicioInput"
                :disabled="desabilitaCampoInicio"
              />
              <b-input-group-append>
                <b-form-datepicker
                  class="form-control d-flex align-items-center"
                  locale="pt-BR"
                  v-model="search.inicio"
                  button-only
                  right
                  :hide-header="true"
                  label-help="Selecione a data de inicio"
                  button-variant="custom-blue"
                  clearable
                  @input="setInicioPicker"
                  :disabled="desabilitaCampoInicio"
                  :initial-date="dataMinima"
                  :min="dataMinima"
                  :max="dataMaxima"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="errors[0]" class="text-danger">
              {{ errors[0] }}<br>
              {{msgErroDataInicio}}
            </small>
          </b-form-group>
      </validation-provider>
      </b-col>

      <b-col sm="6" md="6" lg="6">
        <validation-provider ref="finalProvider" #default="{ errors }" name="Final" :rules="rules">
          <b-form-group
            label="Final"
            label-for="final-input"
            label-class="font_size_label"
          >
            <template #label>
              <span v-b-tooltip.hover.right="textTooltipDataMaxima">Final</span>
            </template>

            <b-input-group>
              <b-form-input
                class="form-control"
                id="final-input"
                v-model="input.final"
                v-mask="'##/##/####'"
                placeholder="00/00/0000"
                autocomplete="off"
                @input="setFinalInput"
                :disabled="desabilitaCampoFinal"
              />
              <b-input-group-append>
                <b-form-datepicker
                  class="form-control d-flex align-items-center"
                  locale="pt-BR"
                  v-model="search.final"
                  button-only
                  right
                  :hide-header="true"
                  label-help="Selecione a data final"
                  button-variant="custom-blue"
                  clearable
                  @input="setFinalPicker"
                  :disabled="desabilitaCampoFinal"
                  :initial-date="dataMaxima"
                  :min="dataMinima"
                  :max="dataMaxima"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="errors[0]" class="text-danger">
              {{ errors[0] }}<br>
              {{msgErroDataFinal}}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from "bootstrap-vue";
import moment from "moment";
import {TIPO_RELATORIO_ENUM } from "@/views/custom-pages/relatorio/config";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    BBadge,
    BCol,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BIcon,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idTipoRelatorio: {
      required: true,
      type: Number
    },
    dataMinima: {
      type: String,
      required: true,
    },
    dataMaxima: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      moment,
      search: {
        inicio: "",
        final: "",
      },
      input: {
        inicio: "",
        final: "",
      },
      erroDataInicio: false,
      erroDataFinal: false,
      relatoriosObrigatorios: [
        TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID,
        TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID,
        TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID,
        TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID,
      ],
      ehObrigatorio: false,
      rules: '',
    };
  },
  beforeMount() {
    this.ehObrigatorio = this.relatoriosObrigatorios.includes(this.idTipoRelatorio);
    if (this.ehObrigatorio) {
      this.input.inicio = this.formataDataParaHumano(this.dataMinima)
      this.search.inicio = this.dataMinima
      
      this.input.final = this.formataDataParaHumano(this.dataMaxima)
      this.search.final = this.dataMinima
      this.rules = 'required'
    }
    this.resetValidations();
  },
  methods: {
    formataDataParaHumano(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    formataDataParaBanco(data) {
      return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    setInicioInput() {
      if (this.input.inicio.length == 10) {
        let inicio = this.formataDataParaBanco(this.input.inicio);

        if (!this.ehObrigatorio) {
          this.search.inicio = inicio;
          return;
        }

        if (this.validaDataMaiorOuIgual(inicio)) {
          this.erroDataInicio = false;
          this.search.inicio = inicio;
          return;
        }
        
        this.setDataInicioComErro();
      }
    },
    setInicioPicker() {
      if (!this.ehObrigatorio) {
        this.input.inicio = this.formataDataParaHumano(this.search.inicio);
        return;
      }

      if (this.validaDataMaiorOuIgual(this.search.inicio)) {
        this.erroDataInicio = false;
        this.input.inicio = this.formataDataParaHumano(this.search.inicio);
        return;
      }
      
      this.setDataInicioComErro();
    },
    setFinalInput() {
      if (this.input.final.length == 10) {
        let final = this.formataDataParaBanco(this.input.final);
        if (!this.ehObrigatorio) {
          this.search.final = final;
          return;
        }

        if (this.validaDataMenorOuIgual(final)) {
          this.erroDataFinal = false;
          this.search.final = final;
          return;
        }
        
        this.setDataFinalComErro();
      }
    },
    setFinalPicker() {
      if (!this.ehObrigatorio) {
        this.input.final = this.formataDataParaHumano(this.search.final);
        return;
      }

      if (this.validaDataMenorOuIgual(this.search.final)) {
        this.erroDataFinal = false;
        this.input.final = this.formataDataParaHumano(this.search.final);
        return;
      }
      
      this.setDataFinalComErro();
    },
    setDataInicioComErro() {
      this.erroDataInicio = true;
      this.input.inicio = '';
      this.search.inicio = '';
    },
    setDataFinalComErro() {
      this.erroDataFinal = true;
      this.input.final = '';
      this.search.final = '';
    },
    validaDataMaiorOuIgual(dataDB) {
      return moment(dataDB).isSameOrAfter(this.dataMinima);
    },
    validaDataMenorOuIgual(dataDB) {
      return moment(dataDB).isSameOrBefore(this.dataMaxima);
    },
    resetValidations() {
      this.$nextTick(() => {
        if (this.$refs.inicioProvider) {
          this.$refs.inicioProvider.reset();
        }
        if (this.$refs.finalProvider) {
          this.$refs.finalProvider.reset();
        }
      });
    },
  },
  watch: {
    "input.inicio": function () {
      this.$emit("selected", this.input);
    },
    "input.final": function () {
      this.$emit("selected", this.input);
    },
    "search.inicio": function () {
      this.$emit("selectedNotFormatted", this.search);
    },
    "search.final": function () {
      this.$emit("selectedNotFormatted", this.search);
    },
  },
  computed: {
    desabilitaCampoInicio() {
      if (!this.ehObrigatorio) {
        return this.disabled
      }
      return  this.disabled || this.dataMinima.length == 0
    },
    desabilitaCampoFinal() {
      if (!this.ehObrigatorio) {
        return this.disabled
      }
      return  this.disabled || this.dataMaxima.length == 0
    },
    msgErroDataInicio() {
      return this.ehObrigatorio && this.dataMinima.length > 0 ? 'A data deve ser maior ou igual a ' + moment(this.dataMinima).format('DD/MM/YYYY') : '';
    },
    msgErroDataFinal() {
      return this.ehObrigatorio && this.dataMaxima.length > 0 ? 'A data deve ser menor ou igual a ' + moment(this.dataMaxima).format('DD/MM/YYYY') : '';
    },
    textTooltipDataMinima() {
      return this.dataMinima.length == 10 ? this.msgErroDataInicio : '';
    },
    textTooltipDataMaxima() {
      return this.dataMaxima.length == 10 ? this.msgErroDataFinal : '';
    },
  }
};
</script>

<style>
.font_size_label {
  font-size: 15px;
  margin-top: -5px;
}
.badge-light-blue {
  background: rgba(39, 114, 192, 0.12);
  color: #5e5873;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0;
}
#tableSize {
  min-width: 80px;
}
</style>
