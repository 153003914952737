<template>
  <b-modal
    id="show-aviso"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    v-model="showModal"
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center my-2 text-center"
    >
      <feather-icon id="modal-confirm" :icon="icon" size="80" :class="classe" />
      <h2 class="text-center">{{ titulo }}</h2>
      <p class="modal-text">{{ texto }}</p>
      <div class="d-flex mt-1">
        <b-button variant="primary-button" @click="confirmAction">
          Entendido
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
    
<script>
import { BModal, BButton, BLink } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BLink,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "success",
      required: true,
    },
  },
  methods: {
    confirmAction(modalEvent) {
      this.$emit("confirmed");
      modalEvent.preventDefault();
    },
  },
  computed: {
    titulo() {
      return this.modalType == "success"
        ? "Estamos processando"
        : "Não há dados para exportar";
    },
    texto() {
      return this.modalType == "success"
        ? "Você será notificado quando a geração do relatório for concluída."
        : "Não há dados de adesão de empresas em campanhas de vacinação para os filtros de pesquisa selecionados, por favor escolha valores válidos.";
    },
    icon() {
      return this.modalType == "success"
        ? "AlertTriangleIcon"
        : "AlertCircleIcon";
    },
    classe() {
      return this.modalType == "success" ? "icon-pendencia" : "icon-error";
    },
  },
};
</script>


<style scoped>
.icon-pendencia {
  color: #ff9110;
  margin-bottom: 18px;
}
.icon-error {
  color: #ea5455;
  margin-bottom: 18px;
}
.modal-text {
  font-size: 14px;
  line-height: 21px;
  max-width: 383px;
}
</style>