<template>
  <b-form-group label="Região" label-for="regiao" label-class="font_size_label">
    <validation-provider #default="{ errors }" name="Região">
      <v-select
        id="regiao"
        :options="options"
        v-model="selected"
        :placeholder="placeholder"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
        :disabled="loading"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
    estado:{
      required: false,
    }
  },
  data() {
    return {
      options: [],
      selected: this.value,
      placeholder: "Carregando...",
      loading: true,
      parametros: {
        params: {
          paginacao: false,
          ativo: true,
          divisao_por_regiao: true,
        },
      },
    };
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    verificaSeTemValor(item) {
      return item != undefined && item != null && item.length > 0;
    },

    setResponse(data) {
      this.options =
        typeof data != "object"
          ? []
          : data.map((regiao) => {
              return {
                id_regiao: regiao.id_regiao,
                descricao: regiao.departamento_uf + " - " + regiao.descricao,
              };
            });
    },

    async setOptions() {
      if (this.optionsConsulted.length > 0) {
        this.options = this.optionsConsulted;
        return;
      }

      let temEstado = this.verificaSeTemValor(this.estado);
      this.selected = null;

      if(temEstado){
        this.getRegiaoEstado(this.estado.map((x) => x.uf));
      } else {
        this.getRegiao();
      }
    },

    async getRegiao(){
      await this.$http
        .get(this.$api.regiao(), this.parametros)
        .then(({ data }) => {
          this.setResponse(data);
        });
    },

    async getRegiaoEstado(uf_estados) {
      await this.$http
        .get(this.$api.regiaoEstado(uf_estados), this.parametros)
        .then(({ data }) => {
          this.setResponse(data)
        });
    },

    verificaMensagemEmCampoBloqueado() {
        if(this.loading && this.options.length == 0){
          return "Estado(s) selecionado(s) não tem divisão por região";
        }
        return "Nenhum registro encontrado";
    }
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.loading = this.options.length == 0;
      this.placeholder = this.loading
        ? this.verificaMensagemEmCampoBloqueado()
        : "Selecione a região";
      this.$emit("options", valor);
    },
    estado: function () {
      this.setOptions();
    },
  },
};
</script>

