<template>
  <b-form-group
    label="Status da Campanha"
    label-for="statusDaCamapanha"
    label-class="font_size_label"
  >
    <validation-provider #default="{ errors }" name="Status da Campanha">
      <v-select
        id="statusDaCamapanha"
        :options="options"
        v-model="selected"
        placeholder="Todos os status"
        label="descricao"
        :clearable="true"
        :multiselect="ehMultiplo"
        :multiple="ehMultiplo"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { TIPO_RELATORIO_ENUM } from "../config";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    idTipoRelatorio: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      options: [],
      selected: this.value,
      ehMultiplo: true,
      relatoriosMultiplos: [
        TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID
      ]
    };
  },
  beforeMount() {
    this.options = [
      { descricao: "Em Andamento", status_da_campanha: "Em Andamento" },
      { descricao: "Concluída", status_da_campanha: "Concluída" },
    ];
  },
  mounted() {
    this.ehMultiplo = this.relatoriosMultiplos.includes(this.idTipoRelatorio);
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
  },
};
</script>

