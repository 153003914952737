<template>
  <b-form-group label="Estado" label-for="estado" label-class="font_size_label">
    <validation-provider #default="{ errors }" name="Estado">
      <v-select
        id="estado"
        :options="options"
        v-model="selected"
        placeholder="Selecione o estado"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import perfis from "@/regras/perfis";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [],
      selected: this.value,
      parametros: {
        params: {
          paginacao: false,
        },
      },
      userData: getUserData(),
    };
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    async setOptions() {
      if (this.optionsConsulted.length > 0) {
        this.options = this.optionsConsulted;
        return;
      }

      await this.$http
        .get(this.$api.estadoRelatorio(), this.parametros)
        .then(({ data }) => {
          this.options = data.map((estado) => {
            return {
              uf: estado.uf,
              descricao: estado.nome,
            };
          });
        });
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.$emit("options", valor);
    },
  },
};
</script>

