<template>
  <b-form-group
    label="Situação do Agendamento"
    label-for="situacaoAgendamento"
    label-class="font_size_label situacaoAgendamento"
  >
    <validation-provider #default="{ errors }" name="Situação do Agendamento">
      <v-select
        id="situacaoAgendamento"
        :options="options"
        v-model="selected"
        placeholder="Selecione o Situação do Agendamento"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
        :disabled="disabled"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: this.value,
    };
  },
  beforeMount() {
    this.options = [
      { descricao: "Agendado", situacao_cadastro: "Agendado" },
      { descricao: "Finalizado", situacao_cadastro: "Finalizado" },
      { descricao: "Cancelado", situacao_cadastro: "Cancelado" },
    ];
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
  },
};
</script>

