<template>
  <div class="content-wrapper" id="RelatorioExportar">
    <ModalProcessing
      :showModal="showModal"
      :modalType="modalType"
      @confirmed="fecharModal()"
      v-model="modalType"
    />
    <PageHeader screenName="Exportação Relatórios" :linkItems="linkItems" />

    <main class="card pt-2 px-1 pb-2">
      <span class="d-flex pt-2 pb-2 px-2">
        Apenas os campos sinalizados com asterisco (*) são obrigatórios para gerar um relatório.
        Utilize os demais campos para refinar os resultados do seu relatório. 
      </span>

      <validation-observer ref="tipoCampanhaUsuarioRules">
        <b-form>
          <!-- Relatório* -->
          <b-row class="pt-2 px-2 d-flex align-items-center">
            <b-col lg="4" md="6" sm="12" class="pr-0">
              <b-form-group
                label="Relatório*"
                label-for="tipoRelatorio"
                label-class="font_size_label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Relatório"
                  rules="required"
                >
                  <v-select
                    id="tipoRelatorio"
                    :options="filtros.tipoRelatorioOptions"
                    v-model="filtros.tipoRelatorioSelected"
                    placeholder="Selecione o relatório"
                    label="descricao"
                    :clearable="false"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <feather-icon
                v-if="tooltip"
                v-b-tooltip.hover.right
                :title="tooltip"
                size="22" icon="HelpCircleIcon" class="help-icon mt-1" />
          </b-row>

          <b-row
            class="pt-2 px-2"
            :class="classHtml"
            id="filtros"
            v-if="filtros.tipoRelatorioSelected != null"
          >
            <!-- Departamento -->
            <b-col
              v-if="visibilidade.departamento"
              :class="geraClassesGrid(colunas.departamento)"
            >
              <departamento
                @selected="handleDepartamentoSelected"
                @options="handleDepartamentoOptions"
                :value="filtros.departamentoSelected"
                :optionsConsulted="filtros.departamentoOptions"
                :key="indices.departamento"
              />
            </b-col>
            <!-- DR Coordenador -->
            <b-col
              v-if="visibilidade.drCoordenador"
              :class="geraClassesGrid(colunas.drCoordenador)"
            >
              <drCoordenador
                @selected="handleDrCoordenadorSelected"
                @options="handleDrCoordenadorOptions"
                :value="filtros.drCoordenadorSelected"
                :optionsConsulted="filtros.drCoordenadorOptions"
                :key="indices.drCoordenador"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
              />
            </b-col>

            <!-- DR Operador -->
            <b-col
              v-if="visibilidade.drOperador"
              :class="geraClassesGrid(colunas.drOperador)"
            >
              <drOperador
                @selected="handleDrOperadorSelected"
                @options="handleDrOperadorOptions"
                :value="filtros.drOperadorSelected"
                :optionsConsulted="filtros.drOperadorOptions"
                :key="indices.drOperador"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
              />
            </b-col>

            <!-- Grupo -->
            <b-col
              v-if="visibilidade.grupo"
              :class="geraClassesGrid(colunas.grupo)"
            >
              <grupo
                @selected="handleGrupoSelected"
                @options="handleGrupoOptions"
                :value="filtros.grupoSelected"
                :optionsConsulted="filtros.grupoOptions"
                :key="indices.grupo"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
              />
            </b-col>

            <!-- Empresa -->
            <b-col
              v-if="visibilidade.empresa"
              :class="geraClassesGrid(colunas.empresa)"
            >
              <empresa
                @selected="handleEmpresaSelected"
                @options="handleEmpresaOptions"
                :value="filtros.empresaSelected"
                :optionsConsulted="filtros.empresaOptions"
                :key="indices.empresa"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :filters="{
                  id_grupo: filtros.grupoSelected
                }"
                :disabled="disabledFields"
              />
            </b-col>

            <!-- Estado -->
            <b-col
              v-if="visibilidade.estado"
              :class="geraClassesGrid(colunas.estado)"
            >
              <estado
                @selected="handleEstadoSelected"
                @options="handleEstadoOptions"
                :value="filtros.estadoSelected"
                :optionsConsulted="filtros.estadoOptions"
                :key="indices.estado"
              />
            </b-col>

            <!-- Região -->
            <b-col
              v-if="visibilidade.regiao"
              :class="geraClassesGrid(colunas.regiao)"
            >
              <regiao
                @selected="handleRegiaoSelected"
                :value="filtros.regiaoSelected"
                :key="indices.regiao"
                :estado="filtros.estadoSelected"
              />
            </b-col>

            <!-- Unidade Operacional -->
            <b-col
              v-if="visibilidade.unidadeOperacional"
              :class="geraClassesGrid(colunas.unidadeOperacional)"
            >
              <unidadeOperacional
                @selected="handleUnidadeOperacionalSelected"
                @options="handleUnidadeOperacionalOptions"
                :value="filtros.unidadeOperacionalSelected"
                :optionsConsulted="filtros.unidadeOperacionalOptions"
                :key="indices.unidadeOperacional"
                :departamento="filtros.departamentoSelected"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :disabled="disabledFields"
              />
            </b-col>

            <!-- Cidade -->
            <b-col
              v-if="visibilidade.cidade"
              :class="geraClassesGrid(colunas.cidade)"
            >
              <cidade
                @selected="handleCidadeSelected"
                @options="handleCidadeOptions"
                :value="filtros.cidadeSelected"
                :optionsConsulted="filtros.cidadeOptions"
                :unidadeOperacional="filtros.unidadeOperacionalSelected"
                :regiao="filtros.regiaoSelected"
                :key="indices.cidade"
                :estado="filtros.estadoSelected"
                :departamento="filtros.departamentoSelected"
                :disabled="disabledFields"
              />
            </b-col>

            <!-- Tipo de Empresa -->
            <b-col
              v-if="visibilidade.tipoDeEmpresa"
              :class="geraClassesGrid(colunas.tipoDeEmpresa)"
            >
              <tipoDeEmpresa
                @selected="handleTipoDeEmpresaSelected"
                @options="handleTipoDeEmpresaOptions"
                :value="filtros.tipoDeEmpresaSelected"
                :optionsConsulted="filtros.tipoDeEmpresaOptions"
                :key="indices.tipoDeEmpresa"
              />
            </b-col>

            <!-- Status da campanha -->
            <b-col
              v-if="visibilidade.statusDaCampanha"
              :class="geraClassesGrid(colunas.statusDaCampanha)"
            >
              <statusDaCampanha
                @selected="handleStatusDaCampanhaSelected"
                :value="filtros.statusDaCampanhaSelected"
                :key="indices.statusDaCampanha"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
              />
            </b-col>

            <!-- Situação de Cadastro -->
            <b-col
              v-if="visibilidade.situacaoCadastro"
              :class="geraClassesGrid(colunas.situacaoCadastro)"
            >
              <situacaoCadastro
                @selected="handleSituacaoCadastroSelected"
                :value="filtros.situacaoCadastroSelected"
                :key="indices.situacaoCadastro"
              />
            </b-col>

            <!-- Situação do Agendamento -->
            <b-col
              v-if="visibilidade.situacaoAgendamento"
              :class="geraClassesGrid(colunas.situacaoAgendamento)"
            >
              <situacaoAgendamento
                @selected="handleSituacaoAgendamentoSelected" 
                :value="filtros.situacaoAgendamento"
                :key="indices.situacaoAgendamento"
                :disabled="disabledFields"
              />
            </b-col>

            <!-- Campanha -->
            <b-col
              v-if="visibilidade.campanha"
              :class="geraClassesGrid(colunas.campanha)"
            >
              <campanha
                @selected="handleCampanhaSelected"
                @options="handleCampanhaOptions"
                :value="filtros.campanhaSelected"
                :optionsConsulted="filtros.campanhaOptions"
                :key="indices.campanha"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :departamento="filtros.departamentoSelected"
                :disabled="disabledFields"
              />
            </b-col>

            <!--  Categoria do Colaborador: -->
            <b-col
              v-if="visibilidade.categoriaDoColaborador"
              :class="geraClassesGrid(colunas.categoriaDoColaborador)"
            >
              <categoriaDoColaborador
                @selected="handleCategoriaDoColaboradorSelected"
                :value="filtros.categoriaDoColaboradorSelected"
                :key="indices.categoriaDoColaborador"
              />
            </b-col>

            <!-- Vacina -->
            <b-col
              v-if="visibilidade.vacina"
              :class="geraClassesGrid(colunas.vacina)"
            >
              <vacina
                @selected="handleVacinaSelected"
                @options="handleVacinaOptions"
                :value="filtros.vacinaSelected"
                :optionsConsulted="filtros.vacinaOptions"
                :key="indices.vacina"
              />
            </b-col>

            <!-- Lote -->
            <b-col
              v-if="visibilidade.lote"
              :class="geraClassesGrid(colunas.lote)"
            >
              <lote
                @selected="handleLoteSelected"
                @options="handleLoteOptions"
                :value="filtros.loteSelected"
                :optionsConsulted="filtros.loteOptions"
                :key="indices.lote"
              />
            </b-col>

            <!-- Vacinador -->
            <b-col
              v-if="visibilidade.vacinador"
              :class="geraClassesGrid(colunas.vacinador)"
            >
              <vacinador
                @selected="handleVacinadorSelected"
                @options="handleVacinadorOptions"
                :value="filtros.vacinadorSelected"
                :optionsConsulted="filtros.vacinadorOptions"
                :key="indices.vacinador"
              />
            </b-col>

            <!-- Realizado por -->
            <b-col
              v-if="visibilidade.realizadoPor"
              :class="geraClassesGrid(colunas.realizadoPor)"
            >
              <realizadoPor
                @selected="handleRealizadoPorSelected"
                :value="filtros.realizadoPorSelected"
                :key="indices.realizadoPor"
              />
            </b-col>

            <!-- Status da Adesão -->
            <b-col
              v-if="visibilidade.statusDaAdesao"
              :class="geraClassesGrid(colunas.statusDaAdesao)"
            >
              <statusDaAdesao
                @selected="handleStatusDaAdesaoSelected"
                :value="filtros.statusDaAdesaoSelected"
                :key="indices.statusDaAdesao"
              />
            </b-col>

            <!-- Período - Data de Adesão: Inicial e/ou Final -->
            <b-col
              v-if="visibilidade.periodoDataAdesao"
              :class="geraClassesGrid(colunas.periodoDataAdesao)"
            >
              <PeriodoDataInicioFinal
                label="Período - Data de Adesão"
                @selected="handlePeriodoDataAdesaoSelected"
                :key="indices.periodoDataAdesao"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :dataMinima="campanhaDatas.minima"
                :dataMaxima="campanhaDatas.maxima"
              />
            </b-col>

            <!-- Período - Data de Cadastro: Inicial e/ou Final -->
            <b-col
              v-if="visibilidade.periodoDataCadastro"
              :class="geraClassesGrid(colunas.periodoDataCadastro)"
            >
              <PeriodoDataInicioFinal
                label="Período - Data de Cadastro"
                @selected="handlePeriodoDataCadastroSelected"
                :key="indices.periodoDataCadastro"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :dataMinima="campanhaDatas.minima"
                :dataMaxima="campanhaDatas.maxima"
              />
            </b-col>
            <!-- Período - Data de Vacinação: Inicial e/ou Final -->
            <b-col
              v-if="visibilidade.periodoDataVacinacao"
              :class="geraClassesGrid(colunas.periodoDataVacinacao)"
            >
              <PeriodoDataInicioFinal
                label="Período - Data da Vacinação"
                @selected="handlePeriodoDataVacinacaoSelected"
                :key="indices.periodoDataVacinacao"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :dataMinima="campanhaDatas.minima"
                :dataMaxima="campanhaDatas.maxima"
              />
            </b-col>
            <!-- Período de Realização: Inicial e/ou Final -->
            <b-col
              v-if="visibilidade.periodoDataRealizacao"
              :class="geraClassesGrid(colunas.periodoDataRealizacao)"
            >
              <PeriodoDataInicioFinal
                label="Período de Realização"
                @selected="handlePeriodoDataRealizacaoSelected"
                :key="indices.periodoDataRealizacao"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :dataMinima="campanhaDatas.minima"
                :dataMaxima="campanhaDatas.maxima"
              />
            </b-col>
            <!-- Período do Agendamento: Inicial e/ou Final -->
            <b-col
              v-if="visibilidade.periodoDataAgendamento"
              :class="geraClassesGrid(colunas.periodoDataAgendamento)"
            >
              <PeriodoDataInicioFinal
                label="Período - Data do Agendamento"
                @selectedNotFormatted="handlePeriodoDataAgendamentoSelected"
                :key="indices.periodoDataAgendamento"
                :idTipoRelatorio="filtros.tipoRelatorioSelected.id_tipo_relatorio"
                :dataMinima="campanhaDatas.minima"
                :dataMaxima="campanhaDatas.maxima"
                :disabled="disabledFields"
              />
            </b-col>            
          </b-row>
          <b-row class="pt-1 pb-2 px-2">
            <b-col class="d-flex justify-content-begin">
              <b-button variant="custom-blue" @click.prevent="gerar" :disabled='disabledGerarRelatorio'>
                <span class="align-middle">Gerar relatório</span>
              </b-button>
              <b-button
                type="reset"
                class="ml-2 cor_botao min-width-button-reset"
                variant="outline-primary"
                @click="cleanSearch"
              >
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Limpar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <TabelaTipoRelatorioUsuario :key="tabelaIndice" />
    </main>
  </div>
</template>
  
<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import { actions, subjects } from "@/libs/acl/rules";
import ModalProcessing from "@/views/components/custom/modals/relatorio/ModalProcessing.vue";
import TabelaTipoRelatorioUsuario from "@/views/custom-pages/relatorio/parts/TabelaTipoRelatorioUsuario.vue";
import PeriodoDataInicioFinal from "@/views/custom-pages/relatorio/parts/PeriodoDataInicioFinal.vue";

import Empresa from "@/views/custom-pages/relatorio/parts/Empresa.vue";
import Estado from "@/views/custom-pages/relatorio/parts/Estado.vue";
import Regiao from "@/views/custom-pages/relatorio/parts/Regiao.vue";
import UnidadeOperacional from "@/views/custom-pages/relatorio/parts/UnidadeOperacional.vue";
import Cidade from "@/views/custom-pages/relatorio/parts/Cidade.vue";
import TipoDeEmpresa from "@/views/custom-pages/relatorio/parts/TipoDeEmpresa.vue";
import StatusDaCampanha from "@/views/custom-pages/relatorio/parts/StatusDaCampanha.vue";
import SituacaoCadastro from "@/views/custom-pages/relatorio/parts/SituacaoCadastro.vue";
import SituacaoAgendamento from "@/views/custom-pages/relatorio/parts/SituacaoAgendamento.vue";
import Campanha from "@/views/custom-pages/relatorio/parts/Campanha.vue";
import CategoriaDoColaborador from "@/views/custom-pages/relatorio/parts/CategoriaDoColaborador.vue";
import Vacina from "@/views/custom-pages/relatorio/parts/Vacina.vue";
import Lote from "@/views/custom-pages/relatorio/parts/Lote.vue";
import Vacinador from "@/views/custom-pages/relatorio/parts/Vacinador.vue";
import RealizadoPor from "@/views/custom-pages/relatorio/parts/RealizadoPor.vue";
import StatusDaAdesao from "@/views/custom-pages/relatorio/parts/StatusDaAdesao.vue";
import DrCoordenador from "@/views/custom-pages/relatorio/parts/DrCoordenador.vue";
import DrOperador from "@/views/custom-pages/relatorio/parts/DrOperador.vue";
import Departamento from "@/views/custom-pages/relatorio/parts/Departamento.vue";
import Grupo from "@/views/custom-pages/relatorio/parts/Grupo.vue";
import helpers from "@/helpers";
import {
  ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_VISIBILIDADE,
  ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_SELECTED,
  EMPRESAS_CADASTRADAS_VISIBILIDADE,
  EMPRESAS_CADASTRADAS_SELECTED,
  QUANTITATIVO_VACINACAO_VISIBILIDADE,
  QUANTITATIVO_VACINACAO_SELECTED,
  COLABORADORES_VACINADOS_VISIBILIDADE,
  COLABORADORES_VACINADOS_VISIBILIDADE_ADMIN_EMPRESA,
  COLABORADORES_VACINADOS_SELECTED,
  TIPO_RELATORIO_ENUM,
  GRID_COLUNAS,
  ADESAO_POR_EMPRESAS_BASE_NACIONAL_VISIBILIDADE,
  ADESAO_POR_EMPRESAS_BASE_NACIONAL_SELECTED,
  AGENDAMENTOS_VISIBILIDADE,
  AGENDAMENTOS_SELECTED,
  COLABORADORES_CADASTRADOS_VISIBILIDADE,
  COLABORADORES_CADASTRADOS_SELECTED,
} from "@/views/custom-pages/relatorio/config";
import { tooltip } from "leaflet";

export default {
  title: "Exportar Relatórios",

  components: {
    BButton,
    BCol,
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BRow,
    BSpinner,
    BTable,
    Campanha,
    CategoriaDoColaborador,
    Cidade,
    ConfirmModal,
    Empresa,
    Estado,
    Lote,
    ModalProcessing,
    PageHeader,
    PeriodoDataInicioFinal,
    RealizadoPor,
    Regiao,
    required,
    SituacaoCadastro,
    StatusDaAdesao,
    StatusDaCampanha,
    SituacaoAgendamento,
    SucessoModal,
    TabelaTipoRelatorioUsuario,
    TipoDeEmpresa,
    UnidadeOperacional,
    Vacina,
    Vacinador,
    ValidationObserver,
    ValidationProvider,
    DrCoordenador,
    DrOperador,
    Departamento,
    Grupo,
    vSelect,
  },

  data() {
    return {
      linkItems: [
        {
          name: "Relatórios",
          active: false,
        },
        {
          name: "Exportar Relatórios",
          active: true,
        },
      ],
      userData: this.$store.state.sessions.userData,
      disabledGerarRelatorio: false,
      tabelaIndice: 0,
      classHtml: "",
      indices: {
        empresa: null,
        estado: null,
        regiao: null,
        unidadeOperacional: null,
        cidade: null,
        tipoDeEmpresa: null,
        statusDaCampanha: null,
        situacaoCadastro: null,
        campanha: null,
        categoriaDoColaborador: null,
        vacina: null,
        lote: null,
        vacinador: null,
        realizadoPor: null,
        statusDaAdesao: null,
        periodoDataAdesao: null,
        periodoDataCadastro: null,
        periodoDataVacinacao: null,
        drCoordenador: null,
        drOperador: null,
        grupo: null,
        departamento: null,
        situacaoAgendamento: null,
        periodoDataAgendamento: null
      },
      visibilidade: {
        empresa: false,
        estado: false,
        regiao: false,
        unidadeOperacional: false,
        cidade: false,
        tipoDeEmpresa: false,
        statusDaCampanha: false,
        situacaoCadastro: false,
        campanha: false,
        categoriaDoColaborador: false,
        vacina: false,
        lote: false,
        vacinador: false,
        realizadoPor: false,
        statusDaAdesao: false,
        periodoDataAdesao: false,
        periodoDataCadastro: false,
        periodoDataVacinacao: false,
        drCoordenador: false,
        drOperador: false,
        grupo: false,
        departamento: false,
        situacaoAgendamento: false,
        periodoDataAgendamento: false
      },

      filtros: {
        campanhaSelected: null,
        categoriaDoColaboradorSelected: null,
        cidadeSelected: null,
        empresaSelected: null,
        estadoSelected: null,
        loteSelected: null,
        periodoDataAdesaoSelected: null,
        periodoDataCadastroSelected: null,
        periodoDataVacinacaoSelected: null,
        periodoDataRealizacaoSelected: null,
        realizadoPorSelected: null,
        regiaoSelected: null,
        situacaoCadastroSelected: null,
        statusDaAdesaoSelected: null,
        statusDaCampanhaSelected: null,
        tipoDeEmpresaSelected: null,
        tipoRelatorioSelected: null,
        unidadeOperacionalSelected: null,
        vacinadorSelected: null,
        vacinaSelected: null,
        drCoordenadorSelected: null,
        drOperadorSelected: null,
        grupoSelected: null,
        departamentoSelected: null,
        situacaoAgendamentoSelected: null,
        periodoDataAgendamentoSelected: null,

        tipoRelatorioOptions: [],
        empresaOptions: [],
        unidadeOperacionalOptions: [],
        campanhaOptions: [],
        realizadoPorOptions: [],
        statusDaAdesaoOptions: [],
        estadoOptions: [],
        regiaoOptions: [],
        cidadeOptions: [],
        tipoDeEmpresaOptions: [],
        situacaoCadastroOptions: [],
        periodoDataCadastroOptions: [],
        drCoordenadorOptions: [],
        drOperadorOptions: [],
        grupoOptions: [],
        departamentoOptions: [],
        situacaoAgendamentoOptions: [],
      },
      permissaoTipoRelatorio: [
        {
          id_tipo_relatorio: TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_COLABORADORES_VACINADOS
          ),
        },
        {
          id_tipo_relatorio: TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_QUANTITATIVO_VACINACAO
          ),
        },
        {
          id_tipo_relatorio: TIPO_RELATORIO_ENUM.EMPRESAS_CADASTRADAS_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_EMPRESAS_CADASTRADAS
          ),
        },
        {
          id_tipo_relatorio:
            TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL
          ),
        },
        {
          id_tipo_relatorio:
            TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_ADESAO_POR_EMPRESAS_BASE_NACIONAL
          ),
        },
        {
          id_tipo_relatorio:
            TIPO_RELATORIO_ENUM.AGENDAMENTOS_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_AGENDAMENTOS
          ) || this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_AGENDAMENTOS_DN
          ),
        },
        {
          id_tipo_relatorio:
            TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID,
          temAcesso: this.$can(
            actions.GERENCIAR,
            subjects.RELATORIO_EXPORTACAO_COLABORADORES_CADASTRADOS
          ),
        },
      ],
      showModal: false,
      modalType: "success",
      colunas: GRID_COLUNAS,
      disabledFields: false,
      campanhaDatas: {
        minima: '',
        maxima: '',
      }
    };
  },

  beforeMount() {
    this.setVisibilidade();
    this.setTipoRelatorio();
  },

  methods: {
    formataParametro(filtro) {
      return filtro == null ||
        typeof filtro == "array" ||
        typeof filtro == "object"
        ? filtro
        : [filtro];
    },
    setParams() {
      const filtros = this.filtros;

      return {
        tipo_relatorio: filtros.tipoRelatorioSelected,
        campanha: this.formataParametro(filtros.campanhaSelected),
        categoria_do_colaborador: this.formataParametro(
          filtros.categoriaDoColaboradorSelected
        ),
        cidade: this.formataParametro(filtros.cidadeSelected),
        empresa: this.formataParametro(filtros.empresaSelected),
        estado: this.formataParametro(filtros.estadoSelected),
        lote: this.formataParametro(filtros.loteSelected),
        periodo_data_adesao: filtros.periodoDataAdesaoSelected,
        periodo_data_cadastro: filtros.periodoDataCadastroSelected,
        periodo_data_vacinacao: filtros.periodoDataVacinacaoSelected,
        periodo_data_realizacao: filtros.periodoDataRealizacaoSelected,
        realizado_por: this.formataParametro(filtros.realizadoPorSelected),
        regiao: this.formataParametro(filtros.regiaoSelected),
        situacao_cadastro: this.formataParametro(
          filtros.situacaoCadastroSelected
        ),
        status_da_adesao: this.formataParametro(filtros.statusDaAdesaoSelected),
        status_da_campanha: this.formataParametro(
          filtros.statusDaCampanhaSelected
        ),
        tipo_de_empresa: this.formataParametro(filtros.tipoDeEmpresaSelected),
        unidade_operacional: this.formataParametro(
          filtros.unidadeOperacionalSelected
        ),
        vacinador: this.formataParametro(filtros.vacinadorSelected),
        vacina: this.formataParametro(filtros.vacinaSelected),
        dr_coordenador: this.formataParametro(filtros.drCoordenadorSelected),
        dr_operador: this.formataParametro(filtros.drOperadorSelected),
        grupo: this.formataParametro(filtros.grupoSelected),
        departamento: this.formataParametro(filtros.departamentoSelected),
        status_agendamento: this.formataParametro(filtros.situacaoAgendamentoSelected),
        periodo_agendamento: this.formataParametro(filtros.periodoDataAgendamentoSelected),
      };
    },
    gerar() {

      this.$refs.tipoCampanhaUsuarioRules.validate().then((success) => {
        this.modalType = "pending";
        if (success) {
          this.disabledGerarRelatorio = true;
          this.$http
            .post(this.$api.tipoRelatorioUsuario(), this.setParams())
            .then(({ data }) => {
              this.modalType = 'success'
              this.showModal = true;
              this.cleanSearch();
              this.disabledGerarRelatorio = false;
            })
            .catch(() => {
              this.tabela.erroTabela = true;
              this.disabledGerarRelatorio = false;
            });
        }
      });
    },
    cleanSearch() {
      const filtrosKey = Array.from(
        new Set([
          ...ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_SELECTED,
          ...EMPRESAS_CADASTRADAS_SELECTED,
          ...QUANTITATIVO_VACINACAO_SELECTED,
          ...COLABORADORES_VACINADOS_SELECTED,
          ...ADESAO_POR_EMPRESAS_BASE_NACIONAL_SELECTED,
          ...AGENDAMENTOS_SELECTED,
        ])
      ).filter((e) => e !== "tipoRelatorioSelected");

      this.alteraValores("filtros", filtrosKey, null);
      this.alteraValores(
        "indices",
        Object.keys(this.indices),
        helpers.geraNumeroAleatorio()
      );
      this.alteraValores(
        "campanhaDatas",
        Object.keys(this.campanhaDatas),
        ''
      );
      this.$refs.tipoCampanhaUsuarioRules.reset();
    },

    alteraValores(atributo, indices, valor) {
      indices.forEach((i) => (this[atributo][i] = valor));
    },

    setTipoRelatorio() {
      this.$http.get(this.$api.tipoRelatorio()).then(({ data }) => {
        let relatoriosPermitidos = this.permissaoTipoRelatorio
          .map((relatorio) => {
            return relatorio.temAcesso ? relatorio.id_tipo_relatorio : null;
          })
          .filter((value) => value !== null);

        this.filtros.tipoRelatorioOptions = data.filter((relatorio) => 
          relatoriosPermitidos.includes(relatorio.id_tipo_relatorio)
        ).sort((a, b) => 
          a.descricao.localeCompare(b.descricao)
        );
      });
    },

    fecharModal() {
      this.tabelaIndice = helpers.geraNumeroAleatorio();
      this.showModal = false;
    },

    setVisibilidade(chaveTipoRelatorio = null) {
      if (chaveTipoRelatorio != null) {
        this.classHtml = this.setClassHtml(chaveTipoRelatorio);
      }
      this.alteraValores("visibilidade", Object.keys(this.visibilidade), false);
      this.alteraValores(
        "campanhaDatas",
        Object.keys(this.campanhaDatas),
        ''
      );
      this.colunas = { ...this.colunas, ...GRID_COLUNAS };
      this.disabledFields = false;
      switch (chaveTipoRelatorio) {
        case TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_CHAVE:
          this.setVisibilidadeAdesoesPorEmpresas();
          break;
        case TIPO_RELATORIO_ENUM.EMPRESAS_CADASTRADAS_CHAVE:
          this.setVisibilidadeEmpresasCadastradas();
          break;
        case TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_CHAVE:
          this.setVisibilidadeQuantitativoVacinacao();
          break;
        case TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_CHAVE:
          this.setVisibilidadeColaboradoresVacinados();
          break;
        case TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_CHAVE:
          this.setVisibilidadeAdesoesPorEmpresasBaseNacional();
          break;
        case TIPO_RELATORIO_ENUM.AGENDAMENTOS_CHAVE:
          this.setVisibilidadeAgendamentos();
          break;
        case TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_CHAVE:
          this.setVisibilidadeColaboradoresCadastrados();
          break;
        default:
          break;
      }
    },
    setVisibilidadeAdesoesPorEmpresas() {
      this.colunas.empresa = 3;
      this.colunas.unidadeOperacional = 3;
      this.colunas.cidade = 3;
      this.colunas.campanha = 3;
      this.colunas.realizadoPor = 4;
      this.colunas.statusDaAdesao = 4;
      this.colunas.periodoDataAdesao = 4;

      this.alteraValores(
        "visibilidade",
        ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_VISIBILIDADE,
        true
      );
      this.limparOutrosFiltrosSelecionados(
        ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_SELECTED
      );
    },
    setVisibilidadeAdesoesPorEmpresasBaseNacional() {
      this.colunas.drCoordenador = 4;
      this.colunas.drOperador = 4;
      this.colunas.grupo = 4;
      this.colunas.empresa = 4;
      this.colunas.unidadeOperacional = 4;
      this.colunas.cidade = 4;
      this.colunas.campanha = 4;
      this.colunas.periodoDataRealizacao = 4;

      this.alteraValores(
        "visibilidade",
        ADESAO_POR_EMPRESAS_BASE_NACIONAL_VISIBILIDADE,
        true
      );
      this.limparOutrosFiltrosSelecionados(
        ADESAO_POR_EMPRESAS_BASE_NACIONAL_SELECTED
      );
    },

    setVisibilidadeEmpresasCadastradas() {
      this.colunas.estado = 4;
      this.colunas.regiao = 4;
      this.colunas.cidade = 4;
      this.colunas.tipoDeEmpresa = 4;
      this.colunas.situacaoCadastro = 4;
      this.colunas.periodoDataCadastro = 4;

      this.alteraValores(
        "visibilidade",
        EMPRESAS_CADASTRADAS_VISIBILIDADE,
        true
      );
      this.limparOutrosFiltrosSelecionados(EMPRESAS_CADASTRADAS_SELECTED);
    },

    setVisibilidadeQuantitativoVacinacao() {
      this.colunas.empresa = 4;
      this.colunas.tipoDeEmpresa = 4;
      this.colunas.statusDaCampanha = 4;
      this.colunas.campanha = 4;
      this.colunas.vacina = 4;
      this.colunas.periodoDataVacinacao = 4;

      this.alteraValores(
        "visibilidade",
        QUANTITATIVO_VACINACAO_VISIBILIDADE,
        true
      );
      this.limparOutrosFiltrosSelecionados(QUANTITATIVO_VACINACAO_SELECTED);
    },

    setVisibilidadeColaboradoresVacinados() {
      this.colunas.empresa = 4;
      this.colunas.unidadeOperacional = 4;
      this.colunas.statusDaCampanha = 4;
      this.colunas.campanha = 4;
      this.colunas.categoriaDoColaborador = 4;
      this.colunas.vacina = 4;
      this.colunas.lote = 4;
      this.colunas.vacinador = 4;
      this.colunas.periodoDataVacinacao = 4;

      const profiles = this.userData.perfis.map((profile) => profile.id_perfil);

      if(profiles.length === 1 && profiles.includes(this.$perfil.ADMIN_EMPRESA)) {
          this.alteraValores(
            "visibilidade",
            COLABORADORES_VACINADOS_VISIBILIDADE_ADMIN_EMPRESA,
            true
          );
          return;
      }

      this.alteraValores(
        "visibilidade",
        COLABORADORES_VACINADOS_VISIBILIDADE,
        true
      );
      this.limparOutrosFiltrosSelecionados(COLABORADORES_VACINADOS_SELECTED);
    },

    setVisibilidadeAgendamentos() {
      const perfilsPermitidosCampoDepartamento = [
        this.$perfil.ADMIN_DN,
        this.$perfil.SAUDE_DN
      ]

      const idPerfilUsuario = this.userData.perfis[0].id_perfil;

      const ehPermitidoVerCampoDepartamento = perfilsPermitidosCampoDepartamento.includes(idPerfilUsuario)

      this.colunas.departamento = 3;
      this.colunas.empresa = ehPermitidoVerCampoDepartamento ? 3 : 4;
      this.colunas.unidadeOperacional = ehPermitidoVerCampoDepartamento ? 3 : 4;
      this.colunas.cidade = ehPermitidoVerCampoDepartamento ? 3 : 4;
      this.colunas.campanha = ehPermitidoVerCampoDepartamento ? 3 : 4;
      this.colunas.situacaoAgendamento = ehPermitidoVerCampoDepartamento ? 3 : 4;
      this.colunas.periodoDataAgendamento = 4;
      
      this.alteraValores(
        "visibilidade",
        AGENDAMENTOS_VISIBILIDADE,
        true
      );

      this.visibilidade.departamento = ehPermitidoVerCampoDepartamento ? true : false;

      this.disabledFields = this.visibilidade.departamento;

      this.limparOutrosFiltrosSelecionados(
        AGENDAMENTOS_SELECTED
      );
    },
    setVisibilidadeColaboradoresCadastrados() {
      const visibilidadeCampoGrupo = this.$can(
            actions.VISUALIZAR,
            subjects.TIPO_ATUACAO_BASE_NACIONAL_COORDENADOR
          );

      this.colunas.grupo = 4;
      this.colunas.empresa = 4;
      
      this.alteraValores(
        "visibilidade",
        COLABORADORES_CADASTRADOS_VISIBILIDADE,
        true
      );

      this.visibilidade.grupo = visibilidadeCampoGrupo;

      this.limparOutrosFiltrosSelecionados(
        COLABORADORES_CADASTRADOS_SELECTED
      );
    },

    setClassHtml(chaveTipoRelatorio) {
      return chaveTipoRelatorio
        .toLowerCase()
        .replace(/_([a-z])/g, function (match, letter) {
          return letter.toUpperCase();
        });
    },

    limparOutrosFiltrosSelecionados(atuais = []) {
      atuais.push("tipoRelatorioSelected");
      let filtros = Object.keys(this.filtros).filter((chave) =>
        chave.includes("Selected")
      );
      let filtrosFiltrados = filtros.filter(
        (filtro) => !atuais.includes(filtro)
      );

      this.alteraValores("filtros", filtrosFiltrados, null);
    },

    handleDepartamentoSelected(value) {
      this.unidadeOperacionalOptions = [];
      this.cidadeOptions = [];
      this.indices.unidadeOperacional = helpers.geraNumeroAleatorio();
      this.indices.cidade = helpers.geraNumeroAleatorio();
      this.filtros.departamentoSelected = value;
      if(value){
        this.disabledFields = false;
      }
    },

    handleEmpresaSelected(value) {
      this.filtros.empresaSelected = value;
    },
    handleEmpresaOptions(value) {
      this.filtros.empresaOptions = value;
    },

    handleEstadoSelected(value) {
      this.regiaoOptions = [];
      this.cidadeOptions = [];
      this.indices.cidade = helpers.geraNumeroAleatorio();
      this.indices.regiao = helpers.geraNumeroAleatorio();
      this.filtros.estadoSelected = value;
    },
    handleEstadoOptions(value) {
      this.filtros.estadoOptions = value;
    },

    handleRegiaoSelected(value) {
      this.cidadeOptions = [];
      this.indices.cidade = helpers.geraNumeroAleatorio();
      this.filtros.regiaoSelected = value;
    },

    handleUnidadeOperacionalSelected(value) {
      this.cidadeOptions = [];
      this.indices.cidade = helpers.geraNumeroAleatorio();
      this.filtros.unidadeOperacionalSelected = value;
    },
    handleUnidadeOperacionalOptions(value) {
      this.filtros.unidadeOperacionalOptions = value;
    },

    handleCidadeSelected(value) {
      this.filtros.cidadeSelected = value;
    },
    handleCidadeOptions(value) {
      this.filtros.cidadeOptions = value;
    },

    handleTipoDeEmpresaSelected(value) {
      this.filtros.tipoDeEmpresaSelected = value;
    },
    handleTipoDeEmpresaOptions(value) {
      this.filtros.tipoDeEmpresaOptions = value;
    },

    handleStatusDaCampanhaSelected(value) {
      this.filtros.statusDaCampanhaSelected = value;
    },

    handleSituacaoCadastroSelected(value) {
      this.filtros.situacaoCadastroSelected = value;
    },

    handleSituacaoAgendamentoSelected(value) {
      this.filtros.situacaoAgendamentoSelected = value;
    },

    handleCampanhaSelected(value) {
      this.campanhaDatas.minima = value[0] != null ? value[0].inicio_campanha  : '';
      this.campanhaDatas.maxima = value[0] != null ? value[0].termino_campanha : '';
      this.indices.periodoDataAdesao = helpers.geraNumeroAleatorio();
      this.indices.periodoDataCadastro = helpers.geraNumeroAleatorio();
      this.indices.periodoDataVacinacao = helpers.geraNumeroAleatorio();
      this.indices.periodoDataRealizacao = helpers.geraNumeroAleatorio()
      this.indices.periodoDataAgendamento = helpers.geraNumeroAleatorio();
      this.filtros.campanhaSelected = value;
    },
    handleCampanhaOptions(value) {
      this.filtros.campanhaOptions = value;
    },

    handleCategoriaDoColaboradorSelected(value) {
      this.filtros.categoriaDoColaboradorSelected = value;
    },

    handleVacinaSelected(value) {
      this.filtros.vacinaSelected = value;
    },
    handleVacinaOptions(value) {
      this.filtros.vacinaOptions = value;
    },

    handleLoteSelected(value) {
      this.filtros.loteSelected = value;
    },
    handleLoteOptions(value) {
      this.filtros.loteOptions = value;
    },

    handleVacinadorSelected(value) {
      this.filtros.vacinadorSelected = value;
    },
    handleVacinadorOptions(value) {
      this.filtros.vacinadorOptions = value;
    },

    handleRealizadoPorSelected(value) {
      this.filtros.realizadoPorSelected = value;
    },

    handleStatusDaAdesaoSelected(value) {
      this.filtros.statusDaAdesaoSelected = value;
    },

    handlePeriodoDataAdesaoSelected(value) {
      this.filtros.periodoDataAdesaoSelected = value;
    },

    handlePeriodoDataCadastroSelected(value) {
      this.filtros.periodoDataCadastroSelected = value;
    },
    
    handlePeriodoDataVacinacaoSelected(value) {
      this.filtros.periodoDataVacinacaoSelected = value;
    },    

    handlePeriodoDataRealizacaoSelected(value) {
      this.filtros.periodoDataRealizacaoSelected = value;
    },

    handlePeriodoDataAgendamentoSelected(value) {
      this.filtros.periodoDataAgendamentoSelected = value;
    },

    handleDrCoordenadorSelected(value) {
      this.filtros.drCoordenadorSelected = value;
    },

    handleDrCoordenadorOptions(value) {
      this.filtros.drCoordenadorOptions = value;
    },

    handleDepartamentoOptions(value) {
      this.filtros.departamentoOptions = value;
    },

    handleDrOperadorSelected(value) {
      this.filtros.drOperadorSelected = value;
    },

    handleDrOperadorOptions(value) {
      this.filtros.drOperadorOptions = value;
    },

    handleGrupoSelected(value) {
      this.filtros.grupoSelected = value;
    },

    handleGrupoOptions(value) {
      this.filtros.grupoOptions = value;
    },
  },
  watch: {
    "filtros.tipoRelatorioSelected": function (value) {
      let chaveTipoRelatorio = value != null ? value.chave : null;
      this.setVisibilidade(chaveTipoRelatorio);
      this.cleanSearch();
    },
  },

  computed: {
    geraClassesGrid: function () {
      return function (colunas) {
        return `col-sm-12 col-md-6 col-lg-${colunas}`;
      };
    },
    tooltip() {
      let tooltip = null;

      if(
        this.filtros.tipoRelatorioSelected != null && 
        this.filtros.tipoRelatorioSelected.id_tipo_relatorio == TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID
      ) {
        tooltip = 'Ao gerar esse relatório, serão geradas planilhas com os dados mais recentes dos colaboradores ativos de cada uma das empresas selecionadas'
      }
      return tooltip;
    }
  },
};
</script>
<style lang="scss">
#RelatorioExportar {
  #filtros > .col > .form-group {
    padding-bottom: 15px;
  }
  #filtros .vs--searchable .vs__selected-options {
    max-height: 100px;
    overflow: auto;
  }
}

@media (min-width: 992px) {
  .empresasCadastradas .tipoDeEmpresa,
  .empresasCadastradas .situacaoCadastro,
  .adesaoPorEmpresasCampanhaEstadual .realizadoPor,
  .adesaoPorEmpresasCampanhaEstadual .statusDaAdesao,
  .quantitativoVacinacao .campanha,
  .quantitativoVacinacao .vacina,
  .colaboradoresVacinados .lote,
  .colaboradoresVacinados .vacinador,
  .adesaoPorEmpresasBaseNacional .campanha,
  .agendamentos .situacaoAgendamento,
  .agendamentos .campanha {
    margin-top: 37px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .empresasCadastradas .situacaoCadastro,
  .quantitativoVacinacao .vacina {
    margin-top: 37px !important;
  }
}
</style>