<template>
  <b-form-group
    label="Lote / Fabricante"
    label-for="lote"
    label-class="font_size_label lote"
  >
    <validation-provider #default="{ errors }" name="Lote">
      <v-select
        id="lote"
        :options="options"
        v-model="selected"
        placeholder="Selecione o lote / fabricante"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [],
      selected: this.value,
      parametros: {
        params: {
          dependencia: false,
          ativo: false,
          colunaOrdenar: "numero_lote",
          ordem: "asc",
        },
      },
    };
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    async setOptions() {
      if (this.optionsConsulted.length > 0) {
        this.options = this.optionsConsulted;
        return;
      }

      await this.$http
        .get(this.$api.lote(), this.parametros)
        .then(({ data }) => {
          const ativos = data.filter((item) => item.ativo_unidade === true);
          ativos.forEach((lote) => {
            const naoAdicinou = !this.options.some(
              (item) => item.id_lote === lote.id_lote
            );
            if (naoAdicinou) {
              this.options.push({
                id_lote: lote.id_lote,
                descricao: `${lote.numero_lote} / ${lote.descricao_fabricante}`,
              });
            }
          });
        });
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.$emit("options", valor);
    },
  },
};
</script>

